import './DrawerHeader.scss';

import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import StepIndicator from 'fe-design-base/molecules/StepIndicator';
import PropTypes from 'prop-types';

import { getPayrollFlowStep } from 'features/teamView/tracking';

import Loader from 'components/Loader';

import { cxHelpers } from 'util/className';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

const { cx, cxEl } = cxHelpers('DrawerHeader');

const DrawerHeader = ({
  currentStep,
  subtitleIsLoading,
  subtitle,
  showStepper,
  activeIndex,
  headerTitle,
  formSteps,
  onCancelClick,
  bottomDrawerHelpers,
  isPayrollEnrolled,
}) => {
  const trackUx = useTrackUx();
  const handleDrawerCancelClick = useCallback(() => {
    if (showStepper) {
      trackUx(EVENT_ACTIONS.DISMISS_CLICKED, TRACK_ACTION_TYPES.CLICK, {
        add_team_payroll_flow_step: getPayrollFlowStep(currentStep),
      });
    } else {
      trackUx(EVENT_ACTIONS.DISMISS_CLICKED, TRACK_ACTION_TYPES.CLICK, {
        payroll_location: isPayrollEnrolled ? 'yes' : 'no',
      });
    }

    if (onCancelClick) onCancelClick();
    else bottomDrawerHelpers.closeBottomDrawer();
  }, [
    bottomDrawerHelpers,
    currentStep,
    isPayrollEnrolled,
    onCancelClick,
    showStepper,
    trackUx,
  ]);

  return (
    <Box
      spacebetween
      vcenter
      h={showStepper ? 110 : 62}
      bgcolor="mono0"
      ph={32}
      relative
      className={cx()}
    >
      <Box column>
        <Text variant="heading2" color="mono900">
          {headerTitle}
        </Text>
        <Loader isLoading={subtitleIsLoading} size={10}>
          <Box>
            {subtitle && (
              <Text variant="captions" color="mono900">
                {subtitle}
              </Text>
            )}
          </Box>
        </Loader>
      </Box>

      {showStepper && (
        <Box w={630} absolute top={12} left="50%">
          <Box relative left="-50%">
            <StepIndicator
              withLabel
              activeIndex={activeIndex}
              steps={formSteps}
            />
          </Box>
        </Box>
      )}

      <Icon
        iconName="Close"
        size="small"
        onClick={handleDrawerCancelClick}
        color="mono900"
        className={cxEl('close')}
      />
    </Box>
  );
};

DrawerHeader.defaultProps = {
  subtitleIsLoading: false,
};

DrawerHeader.propTypes = {
  currentStep: PropTypes.string,
  subtitle: PropTypes.string,
  showStepper: PropTypes.bool,
  subtitleIsLoading: PropTypes.bool,
  activeIndex: PropTypes.number,
  headerTitle: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  bottomDrawerHelpers: PropTypes.shape({
    closeBottomDrawer: PropTypes.func.isRequired,
  }).isRequired,
};

export default DrawerHeader;
